import React from "react";
import * as style from "./location.module.css";
import { useActiveContext, useActiveUpdateContext } from "../../state/GlobalState";

const Location = ({ data }) => {
    const ActiveContext = useActiveContext();
    const UpdateActiveContext = useActiveUpdateContext();
    return (
        <button
            className={style.location}
            onMouseEnter={() => UpdateActiveContext(data[0])}
            onMouseLeave={() => UpdateActiveContext("")}
            style={{ color: ActiveContext === data[0] ? "var(--sunset)" : "#000000" }}
        >
            <span style={{ display: ActiveContext === data[0] ? "inline-block" : "none" }}>•</span>{" "}
            {data[0]}
        </button>
    );
};

export default Location;
