import React, { useState } from "react";
import * as style from "../styles/map.module.css";
import Toggle from "../components/toggle/Toggle";
import Centre from "../components/toggle/Centre";
import { graphql, Link } from "gatsby";
import { PortableText } from "@portabletext/react";
import GMap from "../components/map/GMap";
import Location from "../components/map/Location";

const components = {
    marks: {
        location: ({ children }) => <Location data={children} />,
    },
};

const Map = ({ data }) => {
    const {
        sanityMap,
        allSanityLocations: { locations },
    } = data;

    const [map, setMap] = useState(null);
    const [centre, setCentre] = useState(sanityMap.centre);

    return (
        <main className={style.map}>
            <div className={style.toggleWrapper}>
                <Toggle to={""} colour={"#000000"} rotate/>
            </div>
            <div className={style.centreWrapper}>
                <Centre centre={sanityMap.centre} setCentre={setCentre} />
            </div>
            <div className={style.text}>
                <div className={style.locations}>
                    <PortableText value={sanityMap._rawLocations} components={components} />
                </div>
                <div className={style.office}>
                    <PortableText value={sanityMap._rawOffice} />
                </div>
            </div>
            <div className={style.wrapper}>
                <GMap map={map} setMap={setMap} centre={centre} setCentre={setCentre} />
            </div>
        </main>
    );
};

export default Map;

export const query = graphql`
    query MapQuery {
        sanityMap {
            _rawLocations(resolveReferences: { maxDepth: 5 })
            _rawOffice
            centre {
                lat
                lng
            }
        }
        allSanityLocations {
            locations: nodes {
                _id
                name
                location {
                    lat
                    lng
                }
            }
        }
    }
`;
